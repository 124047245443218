<template>
  <div>
    <v-row no-gutters>
      <!-- Section Payoff -->
      <v-col cols="12" class="d-flex align-center element">
        <!-- Payoff -->
        <h2
          class="px-4 pb-4"
          style="line-height: normal"
          v-bind:style="{ color: color, 'font-size': font_size }"
          v-html="text"
        ></h2>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
    color: {
      type: String,
      default: "black",
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({}),
  methods: {},
  mounted() {},
  created() {},
  computed: {
    font_size() {
      if (this.dense) {
        if (this.$vuetify.breakpoint.smAndDown) {
          return "1.2rem";
        } else {
          return "2rem";
        }
      } else {
        if (this.$vuetify.breakpoint.smAndDown) {
          return "2rem";
        } else {
          return "2rem";
        }
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
h2 {
  font-weight: bold !important;
}
</style>