<template>
  <div>
    <v-row no-gutters>
      <!-- Section Payoff -->
      <v-col cols="12" class="d-flex align-center element">
        <!-- Payoff -->
        <h1
          class="pa-4"
          style="line-height: normal"
          v-bind:style="{ color: color, 'font-size': font_size }"
          v-html="text"
        ></h1>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
    color: {
      type: String,
      default: "red",
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({}),
  methods: {},
  mounted() {
  },
  created() {},
  computed: {
    font_size() {
      if (this.dense) {
        if (this.$vuetify.breakpoint.smAndDown) {
          return "2rem";
        } else {
          return "2.6rem";
        }
      } else {
        if (this.$vuetify.breakpoint.smAndDown) {
          return "2rem";
        } else {
          return "3rem";
        }
      }
    },
  },
  watch: {},
};
</script>

