<template>
  <div>
    <div class="account_creation account_creation_background black--text">
      <v-row>
        <v-col
          cols="12"
          offset-md="0"
          md="12"
          offset-lg="1"
          lg="10"
          offset-xl="3"
          xl="6"
          class="mt-12"
        >
          <div v-bind:style="{ 'margin-bottom': under_headings_padding }">
            <!-- <heading1Section
              color="black"
              :dense="true"
              text="Non hai ancora un account?"
            />
            <heading2Section
              color="black"
              :dense="true"
              text="Crea ora il tuo account"
            /> -->

            <v-row>
              <v-col offset-md="3" md="6" cols="12" class="pa-8">
                <v-card elevation="12" class="card_account_form">
                  <v-card-title>
                    {{ $t("lp_account_creation_card_title_create_new") }}
                  </v-card-title>
                  <v-card-text>
                    <!-- Email -->
                    <v-text-field
                      class=""
                      name="email"
                      :label="$t('lp_label_new_account_email')"
                      :messages="[$t('lp_label_new_account_email_message_1')]"
                      v-model.trim="email"
                      :error-messages="error.email"
                    >
                    </v-text-field>

                    <!-- Password  -->
                    <v-text-field
                      class="mt-4 password1"
                      name="password1"
                      :label="$t('lp_label_new_account_password1')"
                      v-model.trim="password1"
                      type="password"
                      :error-messages="error.password1"
                    >
                      <!-- <template v-slot:message>

                      </template> -->
                    </v-text-field>
                    <v-progress-linear
                      :background-opacity="0"
                      :color="strength_color"
                      :value="strength_value"
                      style="position: relative; top: -21px"
                    ></v-progress-linear>

                    <!-- Password requirements -->
                    <div class="caption">
                      <div>
                        <div class="d-inline" style="width: 32px"></div>
                        <v-icon small v-if="strength_have_lower" color="green"
                          >mdi-check</v-icon
                        >
                        <v-icon small v-if="!strength_have_lower" color="red"
                          >mdi-close</v-icon
                        >
                        <div class="d-inline ml-3" style="">
                          {{
                            $t(
                              "lp_account_creation_password_strength_lowerchar"
                            )
                          }}
                        </div>
                      </div>
                      <div>
                        <div class="d-inline" style="width: 32px">
                          <v-icon
                            small
                            v-if="strength_have_capital"
                            color="green"
                            >mdi-check</v-icon
                          >
                          <v-icon
                            small
                            v-if="!strength_have_capital"
                            color="red"
                            >mdi-close</v-icon
                          >
                        </div>
                        <div class="d-inline ml-3" style="">
                          {{
                            $t(
                              "lp_account_creation_password_strength_upperchar"
                            )
                          }}
                        </div>
                      </div>

                      <div>
                        <div class="d-inline" style="width: 32px">
                          <v-icon
                            small
                            v-if="strength_have_number"
                            color="green"
                            >mdi-check</v-icon
                          >
                          <v-icon small v-if="!strength_have_number" color="red"
                            >mdi-close</v-icon
                          >
                        </div>
                        <div class="d-inline ml-3" style="">
                          {{
                            $t("lp_account_creation_password_strength_number")
                          }}
                        </div>
                      </div>

                      <div>
                        <div class="d-inline" style="width: 32px">
                          <v-icon
                            small
                            v-if="strength_have_symbol"
                            color="green"
                            >mdi-check</v-icon
                          >
                          <v-icon small v-if="!strength_have_symbol" color="red"
                            >mdi-close</v-icon
                          >
                        </div>
                        <div class="d-inline ml-3" style="">
                          {{
                            $t("lp_account_creation_password_strength_symbol")
                          }}
                        </div>
                      </div>
                      <div>
                        <div class="d-inline" style="width: 32px"></div>
                        <v-icon small v-if="strength_have_length" color="green"
                          >mdi-check</v-icon
                        >
                        <v-icon small v-if="!strength_have_length" color="red"
                          >mdi-close</v-icon
                        >
                        <div class="d-inline ml-3" style="">
                          {{ $t("lp_account_creation_password_strength_len8") }}
                        </div>
                      </div>
                    </div>

                    <!-- Password repeat -->
                    <v-text-field
                      class="password2 mt-4"
                      name="password1"
                      :label="$t('lp_label_new_account_password2')"
                      v-model.trim="password2"
                      type="password"
                      :error-messages="error.password2"
                    >
                      <!-- <template v-slot:message>
                        {{ $t("lp_label_new_account_password1_message_2") }}
                      </template> -->
                    </v-text-field>

                    <!-- Role select -->
                    <!-- <v-select
                      class="mt-8 mb-4"
                      :items="account_roles"
                      v-model="account_role"
                      :label="$t('lp_label_new_account_role')"
                      item-text="text"
                      item-value="range"
                      :error-messages="error.account_role"
                      persistent-hint
                      dense
                    >
                    </v-select> -->
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      min-width="200"
                      color="primary"
                      @click="checkAccountCreationData()"
                      :disabled="account_in_creation"
                      :loading="account_in_creation"
                    >
                      <span class="secondary--text">
                        {{ $t("lp_btn_create_account") }}
                      </span>
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>

                  <v-card-title class="mt-4">
                    {{
                      $t("lp_account_creation_card_title_already_have_account")
                    }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      min-width="200"
                      color="primary"
                      :to="{ name: 'Login' }"
                      class="mb-2"
                    >
                      <span class="secondary--text">
                        {{ $t("lp_btn_access_the_app") }}
                      </span>
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="account_dialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
      overlay-opacity="0.8"
    >
      <v-card>
        <v-card-title> {{ account_dialog_title }} </v-card-title>
        <v-card-text>
          <span class="my-12" v-html="account_dialog_text"></span
        ></v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="account_dialog_btn_type == 'go_login'"
            :to="{ name: 'Login' }"
            color="primary"
            width="200"
          >
            <span class="secondary--text">
              {{ $t("lp_btn_login_account") }}
            </span>
          </v-btn>
          <v-btn
            v-if="account_dialog_btn_type == 'close'"
            @click="account_dialog = false"
            color="primary"
            width="200"
          >
            <span class="secondary--text">
              {{ $t("btn_close") }}
            </span>
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { passwordStrength } from "check-password-strength";

import heading1Section from "@/components/landings/heading1Section.vue";
import heading2Section from "@/components/landings/heading2Section.vue";
import { firebaseFunctions } from "@/plugins/firebase";

import { gtag } from "@/gtag";

function validateEmail(mail) {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    )
  ) {
    return true;
  }
  // alert("You have entered an invalid email address!");
  return false;
}

export default {
  metaInfo: {
    title: "Crea il tuo account SafetyFirst",
  },
  props: {},
  components: {
    heading1Section,
    heading2Section,
  },
  data: () => ({
    email: "",
    password1: "",
    password2: "",
    account_role: "",

    // email: null,
    // password1: null,
    // password2: null,
    // account_role: null,
    account_roles: [
      {
        text: "Titolare",
        value: "owner",
      },
      {
        text: "Consulente per la sicurezza",
        value: "consultant",
      },
      {
        text: "Rspp aziendale",
        value: "rspp",
      },
    ],
    // Password validation
    strength_value: 0,
    strength_color: "red",
    customStrengthCheck: [
      {
        id: 0,
        value: "Too weak",
        minDiversity: 0,
        minLength: 0,
      },
      {
        id: 1,
        value: "Weak",
        minDiversity: 2,
        minLength: 6,
      },
      {
        id: 2,
        value: "Medium",
        minDiversity: 3,
        minLength: 7,
      },
      {
        id: 3,
        value: "Strong",
        minDiversity: 4,
        minLength: 8,
      },
    ],
    strength_contains: [],
    //

    // Errors
    error: {
      email: null,
      password2: null,
      account_role: null,
    },
    //
    account_in_creation: false,
    //
    account_dialog: false,
    account_dialog_btn_type: null,
    account_dialog_title: null,
    account_dialog_text: null,
  }),
  methods: {
    checkAccountCreationData() {
      let formValid = true;

      if (!validateEmail(this.email)) {
        this.error.email = this.$t("lp_error_new_account_bad_email");
        formValid = false;
      }
      // console.log(this.strength_value);
      if (this.strength_value < 100) {
        this.error.password1 = this.$t(
          "lp_error_new_account_password_strength"
        );
        formValid = false;
      }

      if (this.password1 != this.password2) {
        this.error.password2 = this.$t(
          "lp_error_new_account_password_mismatch"
        );
        formValid = false;
      }
      // if (!this.account_role) {
      //   this.error.account_role = this.$t("lp_error_new_account_account_role");
      //   formValid = false;
      //   console.log(1114);

      // }

      if (formValid) {
        this.createAccount();
      }
    },

    createAccount() {
      this.account_in_creation = true;
      let CF = firebaseFunctions.httpsCallable("createNewAccount");

      gtag("event", "account_creation", {});

      CF({
        email: this.email,
        password: this.password2,
        role: this.account_role,
      }).then((return_code) => {
        // console.log("CALL RETURN");
        console.log(return_code.data.code);
        switch (return_code.data.code) {
          case "user-already-exist":
            //   alert('Utente esiste già!')
            this.account_dialog_btn_type = "close";

            this.account_dialog = true;
            this.account_dialog_title = this.$t(
              "lp_account_creation_dialog_title_nok"
            );
            this.account_dialog_text = this.$t(
              "lp_account_creation_dialog_text_account_already_exist",
              { email: this.email }
            );

            break;
          case "user-created-confirmation-sended":
            window.localStorage.setItem("last_login_username", this.email);
            this.account_dialog_btn_type = "go_login";
            this.account_dialog = true;
            this.account_dialog_title = this.$t(
              "lp_account_creation_dialog_title_ok"
            );
            this.account_dialog_text =
              this.$t(
                "lp_account_creation_dialog_text_account_created_wait_confirmation_01"
              ) +
              "<br/><br/>" +
              this.$t(
                "lp_account_creation_dialog_text_account_created_wait_confirmation_02"
              );
            break;

          default:
            this.account_dialog_title = this.$t(
              "lp_account_creation_dialog_title_ok"
            );

            break;
        }
        this.account_in_creation = false;
      });
    },
  },
  mounted() {},
  created() {},
  computed: {
    under_headings_padding() {
      if (this.$vuetify.breakpoint.xs) {
        return "1rem";
      } else if (this.$vuetify.breakpoint.sm) {
        return "1.5rem";
      } else if (this.$vuetify.breakpoint.md) {
        return "2rem";
      } else if (this.$vuetify.breakpoint.lg) {
        return "3rem";
      } else if (this.$vuetify.breakpoint.xl) {
        return "5rem";
      }
    },
    strength_have_lower() {
      return this.strength_contains.includes("lowercase");
    },
    strength_have_capital() {
      return this.strength_contains.includes("uppercase");
    },
    strength_have_symbol() {
      return this.strength_contains.includes("symbol");
    },
    strength_have_number() {
      return this.strength_contains.includes("number");
    },
    strength_have_length() {
      if (!this.password1) return false;
      return this.password1.length >= 8;
    },
    password_same() {
      return this.password1 === this.password2;
    },
  },

  watch: {
    email() {
      this.error.email = null;
    },

    password1(value) {
      this.error.password1 = null;
      if (value.length == 0) {
        this.strength_value = 0;
        this.strength_color = "red";
        this.strength_contains = [];
        return;
      }

      this.strength_contains = passwordStrength(
        value,
        this.customStrengthCheck
      ).contains;

      const allowedSymbols = "!@#$%^&*";

      switch (
        passwordStrength(value, this.customStrengthCheck, allowedSymbols).id
      ) {
        case 0:
          this.strength_value = 25;
          this.strength_color = "red";
          break;
        case 1:
          this.strength_value = 50;
          this.strength_color = "orange";
          break;
        case 2:
          this.strength_value = 75;
          this.strength_color = "yellow";
          break;
        case 3:
          this.strength_value = 100;
          this.strength_color = "green";
          break;
        default:
          break;
      }
    },
    password2() {
      this.error.password2 = null;
    },
    account_role() {
      this.error.account_role = null;
    },
  },
};
</script> 

<style scoped>
.account_creation {
  height: 100vh;
}

.account_creation_background {
  position: relative;
  /* box-shadow: 0px 0px 20px black; */
  z-index: 1;
}

.account_creation_background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* filter: grayscale(50%); */
  /* background-image: url("../../../assets/graphics/landing/backgrounds/newaccount_02.png"); */
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
/* .card_account_form {
  background: #f6f5b4; 
  background: -moz-linear-gradient(
    45deg,
    #f6f5b4 0%,
    #edd817 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f6f5b4 0%,
    #edd817 100%
  ); 
  background: linear-gradient(
    45deg,
    #f6f5b4 0%,
    #edd817 100%
  ); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f5b4', endColorstr='#edd817',GradientType=0 ); 
} */
</style>

<style>
.password1 > .v-input__slot {
  margin-bottom: 0px !important;
}
</style>